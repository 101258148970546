export default class Masonry {
	init() {
		if (jQuery('.masonry-grid').length > 0) {

			var grid = jQuery('.masonry-grid').imagesLoaded(
				function () {
					grid.masonry({
						itemSelector: '.masonry-item',
						percentPosition: true,
						columnWidth: '.masonry-item',
						gutter: 20
					});
				}
			);


			jQuery('[data-fancybox="gallery"]').fancybox({
				helpers: {
					overlay: { locked: false }
				}
			});


		}
	}
}