//import AOS from 'aos/dist/aos';
//import 'aos/dist/aos.css';
import './vendor/slick.min';
import './vendor/masonry.min';
import './vendor/jquery.fancybox.min';
import './vendor/imagesloaded.min';
import $ from 'jquery';

import Faq from './blocks/faq/view.js';
import FullwidthSlider from './blocks/fullwidth-slider/view.js';
import Masonry from './blocks/masonry/view.js';
import Slideshow from './blocks/slideshow/view.js';
import Tabs from './blocks/tabs/view.js';

docReady( function () {

    /*
    AOS.init( {
        once: true
    } )
    */

    let faq = new Faq();
    faq.init();

    let masonry = new Masonry();
    masonry.init();

    let slideshow = new Slideshow();
    slideshow.init();

    let fullwidthslider = new FullwidthSlider();
    fullwidthslider.init();

    let tabs = new Tabs();
    tabs.init();
} )


function docReady ( fn ) {
    if ( document.readyState === "complete" || document.readyState === "interactive" ) {
        setTimeout( fn, 1 );
    } else {
        document.addEventListener( "DOMContentLoaded", fn );
    }
}